import Vue from "vue";

var precoChartData = new Vue({
    data () {
        return {
            type: "bar",
            data: {
                labels: [
                    "50.001 - 80.000",
                    "140.001 - 180.000",
                    "180.001 - 200.000",
                    "240.001 - 280.000",
                    "280.001 - 320.000",
                    "380.001 - 450.000",
                    "550.001 - 700.000",
                    "700.001 - 1.000.000",
                    "1.000.001 - 1.500.000",
                    "Acima de 1.500.000",
                ],
                datasets: [{
                    label: '',
                    data: [
                        31,
                        94,
                        120,
                        126,
                        66,
                        45,
                        67,
                        34,
                        12,
                        6,
                    ],
                    backgroundColor: [
                        'rgb(39, 74, 187)',
                        'rgb(54, 162, 235)',
                        'rgb(255, 205, 86)',
                        'rgb(75, 192, 192)',
                        'rgb(255, 99, 132)',
                        'rgb(255, 159, 64)',
                        'rgb(153, 102, 255)',
                        'rgb(201, 203, 207)',
                        'rgb(235, 166, 169)',
                        'rgb(255, 198, 172)',
                    ],
                    hoverOffset: 4
                }, ]
            },
            options: {
                responsive: true,
                legend: false,
            }
        };
    },
    
})

export default precoChartData;