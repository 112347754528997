<template>
  <div>
    <v-container class="container">
      <h2 class="h3 dashboard-title mt-4 mb-0">Estatísticas de Busca</h2>

      <!-- CARDS -->
      <v-row class="mt-0" align="center">
        <v-col cols="7" class="d-flex justify-left mb-4">
            <v-tabs class="mr-2" background-color="transparent" v-model="intervalo">
              <v-tabs-slider></v-tabs-slider>
              
              <v-tab class="tabs">
                <span class="body-2 font-weight-bold ma-0 pa-0">HOJE</span>
              </v-tab>

              <v-tab class="tabs">
                <span class="body-2 font-weight-bold ma-0 pa-0">SEMANA</span>
              </v-tab>

              <v-tab class="tabs">
                <span class="body-2 font-weight-bold ma-0 pa-0">MÊS</span>
              </v-tab>

              <v-tab class="tabs"
                ><span class="body-2 font-weight-bold ma-0 pa-0">ANO</span>
              </v-tab>

              <v-tab class="tabs"
                ><span class="body-2 font-weight-bold ma-0 pa-0">PERIODO</span>
              </v-tab>

            </v-tabs>
        </v-col>
        <v-col cols="5" class="d-flex justify-end mb-4" style="background-color: transparent; display: flex; align-items: center; justify-content: center"  align="center">
          <div v-if="intervalo == 4">
            <label class="mb-1 caption">data inicial</label>
            <v-text-field
              type="date"
              v-model="periodo_inicial"
              label=""
              solo
              class="mr-6"
            ></v-text-field>
            <!-- v-model="first" -->
          </div>
          <div v-if="intervalo == 4">
            <label class="mb-1 caption">data final</label>
            <v-text-field
              type="date"
              v-model="periodo_final"
              label=""
              solo
            ></v-text-field>
            <!-- v-model="first" -->
          </div>
        </v-col>

        <v-card class="w-100 d-flex flex-row mx-3" style="height:120px">
          <v-col cols="2"
            ><v-card class="card-price" style="height:90px">
              <v-card-text class="h6" style="height:45px"
                >Preço<v-icon class="icon-dashboard"
                  >mdi-currency-usd</v-icon
                ></v-card-text
              ><span>456</span></v-card
            ></v-col
          >
          <v-col cols="2"
            ><v-card class="card-area" style="height:90px">
              <v-card-text class="h6" style="height:45px"
                >Area<v-icon class="icon-dashboard"
                  >mdi-image-size-select-small</v-icon
                ></v-card-text
              ><span>338</span></v-card
            ></v-col
          >
          <v-col cols="2"
            ><v-card class="card-location" style="height:90px">
              <v-card-text class="h6" style="height:45px"
                >Localização<v-icon class="icon-dashboard"
                  >mdi-map-marker-outline</v-icon
                ></v-card-text
              ><span>199</span></v-card
            ></v-col
          >
          <v-col cols="2"
            ><v-card class="card-status" style="height:90px">
              <v-card-text class="h6" style="height:45px"
                >Status<v-icon class="icon-dashboard"
                  >mdi-list-status</v-icon
                ></v-card-text
              ><span>91</span></v-card
            ></v-col
          >
          <v-col cols="2"
            ><v-card class="card-type" style="height:90px">
              <v-card-text class="h6" style="height:45px"
                >Tipo<v-icon class="icon-dashboard"
                  >mdi-home-city-outline</v-icon
                ></v-card-text
              ><span>89</span>
            </v-card></v-col
          >
        </v-card>
      </v-row>
      <!-- FIM CARDS -->

      <!-- GRAFICOS -->
      <v-row class="mt-6">
        <v-col cols="6">
          <v-card class="pb-4 card-container">
            <v-card-text class="h6">Preço</v-card-text>
            <PrecoChart />
          </v-card>
        </v-col>

        <v-col cols="6" style="marine" class="">
          <v-card class="pb-4">
            <v-card-text class="h6">Area</v-card-text>
            <AreaChart />
          </v-card>
        </v-col>

        <v-col cols="3" style="">
          <v-card class="pb-4">
            <v-card-text class="h6">Localização</v-card-text>
            <LocalizacaoChart />
          </v-card>
        </v-col>

        <v-col cols="3" style="marine">
          <v-card class="pb-4">
            <v-card-text class="h6">Status</v-card-text>
            <StatusChart />
          </v-card>
        </v-col>
        <v-col cols="3" style="" class="">
          <v-card class="pb-4">
            <v-card-text class="h6">Tipo</v-card-text>
            <TipoChart />
          </v-card>
        </v-col>

      </v-row>

      <!-- GRAFICOS -->
    </v-container>
  </div>
</template>


<script>
import moment from "moment";
import { API } from "../services/API"
import TipoChart from "../components/Graficos/GraficoTipo.vue";
import LocalizacaoChart from "../components/Graficos/GraficoLocalizacao.vue";
import StatusChart from "../components/Graficos/GraficoStatus.vue";
import PrecoChart from "../components/Graficos/GraficoPreco.vue";
import AreaChart from "../components/Graficos/GraficoArea.vue";
import precoChartData from "../components/Graficos/GraficosJs/preco.js";

export default {
  components: {
    TipoChart,
    LocalizacaoChart,
    StatusChart,
    PrecoChart,
    AreaChart,
  },

  data() {
    return {
      precoChartData  : precoChartData,
      intervalo       : 1,
      periodo_inicial : null,
      periodo_final   : null,
    }
  },

  watch: {
    intervalo() {
      this.estatistica_filtro_avancado(this.intervalo, this.periodo_inicial, this.periodo_final)
    },
    periodo_inicial() {
      this.estatistica_filtro_avancado(this.intervalo, this.periodo_inicial, this.periodo_final)
    },
    periodo_final() {
      this.estatistica_filtro_avancado(this.intervalo, this.periodo_inicial, this.periodo_final)
    }

  },

  mounted() {
      this.estatistica_filtro_avancado(this.intervalo)
  },

  methods: {
    async estatistica_filtro_avancado(p_periodo, p_data_inicial, p_data_final) {
      if (p_periodo == 0) {       // HOJE
        p_data_inicial  = moment().format('DD/MM/YYYY');
        p_data_final    = moment().format('DD/MM/YYYY');
      }
      else if (p_periodo == 1) {  // SEMANA
        p_data_inicial  = moment().day(0).format('DD/MM/YYYY');
        p_data_final    = moment().day(6).format('DD/MM/YYYY');
      }
      else if (p_periodo == 2) {  // MES
        p_data_inicial  = moment().startOf('month').format('DD/MM/YYYY');
        p_data_final    = moment().endOf('month').format('DD/MM/YYYY');
      }
      else if (p_periodo == 3) {  // ANO
        p_data_inicial  = moment().startOf('year').format('DD/MM/YYYY');
        p_data_final    = moment().endOf('year').format('DD/MM/YYYY');
      }
      else if (p_periodo == 4) {  // PERIODO
        p_data_inicial  = moment(this.periodo_inicial).format('DD/MM/YYYY');
        if (!p_data_inicial || p_data_inicial == 'Invalid date') {
          p_data_inicial  = "01/01/1900";
        }
        p_data_final    = moment(this.periodo_final).format('DD/MM/YYYY');
        if (!p_data_final || p_data_final == 'Invalid date')
          p_data_final  = "01/01/2200";
      }

      const datas = {data_inicial : p_data_inicial,
                     data_final   : p_data_final }
      const resp = await API.get(`site/estatistica_filtro_avancado`, {params: datas});
      
      if (resp && resp.data) {
        var resp_data = resp.data.result;
        this.precoChartData.labels = resp_data
        this.precoChartData.data.labels = [];
        this.precoChartData.data.datasets[0].data = [];
        this.precoChartData.data.datasets[0].label = [];
        var _this = this;
        resp_data.map(function(value) {
          _this.precoChartData.data.labels.push(value.label)
          _this.precoChartData.data.datasets[0].data.push(value.data)
        })
        const ctx = document.getElementById("preco-chart");
        const ctx_parent = ctx.parentElement;
        while (ctx_parent.firstChild) {
          ctx_parent.removeChild(ctx_parent.firstChild);
        }
        var canv = document.createElement("canvas")
        canv.setAttribute("id", "preco-chart");
        ctx_parent.appendChild(canv)
        new Chart(canv, this.precoChartData);
      }
    }

  }

};
</script>


<style scoped>
*,
a {
  text-decoration: none;
}

.container {
  max-width: 1200px;
}

.dahsboard-title,
.metreage {
  font-weight: 600;
  font-size: 25px;
  font-family: "Montserrat", sans-serif;
}

.dashboard-title::after {
  display: block;
  width: 50px;
  height: 3px;
  margin-top: 0.5rem;
  content: "";
  background: #274abb;
}

.card-price {
  background: #274abb;
  color: #fff !important;
}

.card-price span {
  /* background: #0231ca; */
  color: #fff !important;
  opacity: 0.8;
}

.card-area {
  background: #36a2eb;
}

.card-area span {
  /* background: #059bff; */
  color: #fff !important;
  opacity: 0.8;
}

.card-location {
  background: #4bc0c0;
  color: #fff !important;
}

.card-location span {
  /* background: #22cece; */
  opacity: 0.8;
}

.card-status {
  background: #9966ff;
  color: #fff !important;
}

.card-status span {
  /* background: #8142ff; */
  opacity: 0.8;
}

.card-type {
  background: #519e8a;
  color: #fff !important;
}

.card-type span {
  /* background: #8142ff; */
  opacity: 0.8;
}


.card-price .h6,
.card-area .h6,
.card-location .h6,
.card-status .h6,
.card-price .h6,
.card-type .h6 {
  color: #fff !important;
  opacity: 0.9;
}

.card-type span {
  background: #389f83;
}

.h6 {
  display: flex;
  justify-content: space-between;
}

.icon-dashboard {
  font-size: 2.2rem;
  color: #fff;
  opacity: 0.6;
}

span {
  margin-top: -12px;
  padding-left: 12px;
  font-size: 1.5rem;
}

/*-----------------------------------*/
/*------ TABS ------*/
/*-----------------------------------*/
.v-tabs, .tabs-container {
  align-self: flex-end!important;
  max-width: 500px!important;
}

@media (max-width: 1024px) {
  .v-tabs {
    max-width: 960px;
  }
}

@media (max-width: 768px) {
  .v-tabs {
    max-width: 704px;
  }
}

@media (max-width: 599px) {
  .v-tabs {
    padding: 0px !important;
  }
}

.tabs {
  width: 80px;
  height: 40px;
  font-size: 0.3rem !important;
  border-radius: 8px 0px 0px 8px;
  background: #f7f7f7ea;
}

.tab-today span,
.tab-month span,
.tab-year span {
  width: 100%;
  text-align: center;
}

.caption {
  color: #000;
  opacity: 0.6;
}

.v-tab--active {
  background: #3959be !important;
  color: #fff !important;
  border-bottom: 0px !important;
}

.v-tabs-slider {
  background: #3959be !important;
  display: none !important;
}
</style>
